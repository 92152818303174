import { t } from "i18n";
import { useAtom } from "jotai";
import { lazy, ReactNode, Suspense, useMemo } from "react";
import { matchPath, Navigate, useLocation } from "react-router-dom";
import { shouldDisableNFTRoutesAtom } from "state/application/atoms";
import { SpinnerSVG } from "theme/components";
import { isBrowserRouterEnabled } from "utils/env";

import {
  getAddLiquidityPageTitle,
  getPositionPageDescription,
  getPositionPageTitle,
} from "pages/getPositionPageTitle";
import { getExploreDescription, getExploreTitle } from "./getExploreTitle";
// High-traffic pages (index and /swap) should not be lazy-loaded.
import Swap from "./Swap";

const NftExplore = lazy(() => import("nft/pages/explore"));
const Collection = lazy(() => import("nft/pages/collection"));
const Profile = lazy(() => import("nft/pages/profile"));
const Asset = lazy(() => import("nft/pages/asset/Asset"));
const AddLiquidityWithTokenRedirects = lazy(
  () => import("pages/AddLiquidity/redirects")
);
const AddLiquidityV2WithTokenRedirects = lazy(
  () => import("pages/AddLiquidityV2/redirects")
);
const RedirectExplore = lazy(() => import("pages/Explore/redirects"));
const MigrateV2 = lazy(() => import("pages/MigrateV2"));
const MigrateV2Pair = lazy(() => import("pages/MigrateV2/MigrateV2Pair"));
const NotFound = lazy(() => import("pages/NotFound"));
const Pool = lazy(() => import("pages/Pool"));
const PositionPage = lazy(() => import("pages/Pool/PositionPage"));
const PoolV2 = lazy(() => import("pages/Pool/v2"));
const PoolDetails = lazy(() => import("pages/PoolDetails"));
const PoolFinder = lazy(() => import("pages/PoolFinder"));
const RemoveLiquidity = lazy(() => import("pages/RemoveLiquidity"));
const RemoveLiquidityV3 = lazy(() => import("pages/RemoveLiquidity/V3"));
const TokenDetails = lazy(() => import("pages/TokenDetails"));
const Vote = lazy(() => import("pages/Vote"));

const LazyLoadSpinner = () => (
  <SpinnerSVG
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
);

interface RouterConfig {
  browserRouterEnabled?: boolean;
  hash?: string;
  shouldDisableNFTRoutes?: boolean;
}

export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled();
  const { hash } = useLocation();
  const [shouldDisableNFTRoutes] = useAtom(shouldDisableNFTRoutesAtom);
  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
      shouldDisableNFTRoutes: Boolean(shouldDisableNFTRoutes),
    }),
    [browserRouterEnabled, hash, shouldDisableNFTRoutes]
  );
}

const StaticTitlesAndDescriptions = {
  UniswapTitle: t("title.uniswapTradeCrypto"),
  SwapTitle: t("title.buySellTradeEthereum"),
  SwapDescription: t("title.swappingMadeSimple"),
  DetailsPageBaseTitle: t("common.buyAndSell"),
  TDPDescription: t("title.realTime"),
  PDPDescription: t("title.tradeTokens"),
  NFTTitle: t("title.explore"),
  MigrateTitle: t("title.migratev2"),
  MigrateDescription: t("title.easilyRemove"),
  AddLiquidityDescription: t("title.earnFees"),
};

export interface RouteDefinition {
  path: string;
  nestedPaths: string[];
  getTitle: (path?: string) => string;
  getDescription: (path?: string) => string;
  enabled: (args: RouterConfig) => boolean;
  getElement: (args: RouterConfig) => ReactNode;
}

function createRouteDefinition(
  route: Partial<RouteDefinition>
): RouteDefinition {
  return {
    getElement: () => null,
    getTitle: () => StaticTitlesAndDescriptions.UniswapTitle,
    getDescription: () => StaticTitlesAndDescriptions.SwapDescription,
    enabled: () => true,
    path: "/",
    nestedPaths: [],
    ...route,
  };
}

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: "/",
    getTitle: () => StaticTitlesAndDescriptions.SwapTitle,
    getDescription: () => StaticTitlesAndDescriptions.SwapDescription,
    getElement: () => <Navigate to="/swap" replace />,
  }),
  createRouteDefinition({
    path: "/explore",
    getTitle: getExploreTitle,
    getDescription: getExploreDescription,
    nestedPaths: [":tab", ":chainName", ":tab/:chainName"],
    getElement: () => <RedirectExplore />,
  }),
  createRouteDefinition({
    path: "/explore/tokens/:chainName/:tokenAddress",
    getTitle: () => t("common.buyAndSell"),
    getDescription: () => StaticTitlesAndDescriptions.TDPDescription,
    getElement: () => <TokenDetails />,
  }),
  createRouteDefinition({
    path: "/tokens",
    getTitle: getExploreTitle,
    getDescription: getExploreDescription,
    getElement: () => <Navigate to="/explore/tokens" replace />,
  }),
  createRouteDefinition({
    path: "/tokens/:chainName",
    getTitle: getExploreTitle,
    getDescription: getExploreDescription,
    getElement: () => <RedirectExplore />,
  }),
  createRouteDefinition({
    path: "/tokens/:chainName/:tokenAddress",
    getTitle: () => StaticTitlesAndDescriptions.DetailsPageBaseTitle,
    getDescription: () => StaticTitlesAndDescriptions.TDPDescription,
    getElement: () => <RedirectExplore />,
  }),
  createRouteDefinition({
    path: "/explore/pools/:chainName/:poolAddress",
    getTitle: () => StaticTitlesAndDescriptions.DetailsPageBaseTitle,
    getDescription: () => StaticTitlesAndDescriptions.PDPDescription,
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    ),
  }),
  createRouteDefinition({
    path: "/vote/*",
    getTitle: () => t("title.voteOnGov"),
    getDescription: () => t("title.uniToken"),
    getElement: () => (
      <Suspense fallback={<LazyLoadSpinner />}>
        <Vote />
      </Suspense>
    ),
  }),
  // createRouteDefinition({
  //   path: "/create-proposal",
  //   getTitle: () => t("title.createGovernanceOn"),
  //   getDescription: () => t("title.createGovernanceTo"),
  //   getElement: () => <Navigate to="/vote/create-proposal" replace />,
  // }),
  // createRouteDefinition({
  //   path: "/buy",
  //   getElement: () => <Swap />,
  //   getTitle: () => StaticTitlesAndDescriptions.SwapTitle,
  // }),
  createRouteDefinition({
    path: "/send",
    getElement: () => <Swap />,
    getTitle: () => t("title.sendTokens"),
  }),
  createRouteDefinition({
    path: "/limits",
    getElement: () => <Navigate to="/limit" replace />,
    getTitle: () => t("title.placeLimit"),
  }),
  createRouteDefinition({
    path: "/limit",
    getElement: () => <Swap />,
    getTitle: () => t("title.placeLimit"),
  }),
  createRouteDefinition({
    path: "/swap",
    getElement: () => <Swap />,
    getTitle: () => StaticTitlesAndDescriptions.SwapTitle,
  }),
  createRouteDefinition({
    path: "/pool/v2/find",
    getElement: () => <PoolFinder />,
    getTitle: () => t("title.importLiquidityv2"),
    getDescription: () => t("title.useImportTool"),
  }),
  createRouteDefinition({
    path: "/pool/v2",
    getElement: () => <PoolV2 />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
  }),
  createRouteDefinition({
    path: "/pool",
    getElement: () => <Pool />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
  }),
  createRouteDefinition({
    path: "/pool/:tokenId",
    getElement: () => <PositionPage />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
  }),
  createRouteDefinition({
    path: "/pools/v2/find",
    getElement: () => <PoolFinder />,
    getTitle: () => t("title.importLiquidityv2"),
    getDescription: () => t("title.useImportTool"),
  }),
  createRouteDefinition({
    path: "/pools/v2",
    getElement: () => <PoolV2 />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
  }),
  createRouteDefinition({
    path: "/pools",
    getElement: () => <Pool />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
  }),
  createRouteDefinition({
    path: "/pools/:tokenId",
    getElement: () => <PositionPage />,
    getTitle: getPositionPageTitle,
    getDescription: getPositionPageDescription,
  }),
  createRouteDefinition({
    path: "/add/v2",
    nestedPaths: [":currencyIdA", ":currencyIdA/:currencyIdB"],
    getElement: () => <AddLiquidityV2WithTokenRedirects />,
    getTitle: getAddLiquidityPageTitle,
    getDescription: () => StaticTitlesAndDescriptions.AddLiquidityDescription,
  }),
  createRouteDefinition({
    path: "/add",
    nestedPaths: [
      ":currencyIdA",
      ":currencyIdA/:currencyIdB",
      ":currencyIdA/:currencyIdB/:feeAmount",
      ":currencyIdA/:currencyIdB/:feeAmount/:tokenId",
    ],
    getElement: () => <AddLiquidityWithTokenRedirects />,
    getTitle: getAddLiquidityPageTitle,
    getDescription: () => StaticTitlesAndDescriptions.AddLiquidityDescription,
  }),
  createRouteDefinition({
    path: "/remove/v2/:currencyIdA/:currencyIdB",
    getElement: () => <RemoveLiquidity />,
    getTitle: () => t("title.removeLiquidityv2"),
    getDescription: () => t("title.removeTokensv2"),
  }),
  createRouteDefinition({
    path: "/remove/:tokenId",
    getElement: () => <RemoveLiquidityV3 />,
    getTitle: () => t("title.removePoolLiquidity"),
    getDescription: () => t("title.removev3Liquidity"),
  }),
  createRouteDefinition({
    path: "/migrate/v2",
    getElement: () => <MigrateV2 />,
    getTitle: () => StaticTitlesAndDescriptions.MigrateTitle,
    getDescription: () => StaticTitlesAndDescriptions.MigrateDescription,
  }),
  createRouteDefinition({
    path: "/migrate/v2/:address",
    getElement: () => <MigrateV2Pair />,
    getTitle: () => StaticTitlesAndDescriptions.MigrateTitle,
    getDescription: () => StaticTitlesAndDescriptions.MigrateDescription,
  }),
  // createRouteDefinition({
  //   path: '/nfts',
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <NftExplore />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  //   getTitle: () => t('title.exploreNFTs'),
  //   getDescription: () => t('title.betterPricesMoreListings'),
  // }),
  // createRouteDefinition({
  //   path: '/nfts/asset/:contractAddress/:tokenId',
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Asset />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  //   getTitle: () => StaticTitlesAndDescriptions.NFTTitle,
  // }),
  // createRouteDefinition({
  //   path: '/nfts/profile',
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Profile />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  //   getTitle: () => StaticTitlesAndDescriptions.NFTTitle,
  //   getDescription: () => t('title.manageNFT'),
  // }),
  // createRouteDefinition({
  //   path: '/nfts/collection/:contractAddress',
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Collection />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  //   getTitle: () => StaticTitlesAndDescriptions.NFTTitle,
  // }),
  // createRouteDefinition({
  //   path: '/nfts/collection/:contractAddress/activity',
  //   getElement: () => (
  //     <Suspense fallback={null}>
  //       <Collection />
  //     </Suspense>
  //   ),
  //   enabled: (args) => !args.shouldDisableNFTRoutes,
  //   getTitle: () => StaticTitlesAndDescriptions.NFTTitle,
  // }),
  createRouteDefinition({
    path: "*",
    getElement: () => <Navigate to="/not-found" replace />,
  }),
  createRouteDefinition({ path: "/not-found", getElement: () => <NotFound /> }),
];

export const findRouteByPath = (pathname: string) => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      return route;
    }
    const subPaths = route.nestedPaths.map(
      (nestedPath) => `${route.path}/${nestedPath}`
    );
    for (const subPath of subPaths) {
      const match = matchPath(subPath, pathname);
      if (match) {
        return route;
      }
    }
  }
  return undefined;
};
